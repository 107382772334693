import React from "react";
import { Container, Row, Col, Button } from "reactstrap";
import useAuth from "../hooks/useAuth";
import useRouter from "../hooks/useRouter";
import { useEffect } from "react";
import routes from "../routes";
import Layout from "./Layout";

export default function Landing() {
  const { user } = useAuth();
  const { routeTo } = useRouter();

  useEffect(() => {
    if (user) {
      routeTo(routes.dashboard);
    }
  });

  return (
    <Layout>
      <div className="min-vh-100 d-flex align-items-center justify-content-center">
        <Container>
          <Row>
            <Col
              className="mt-5 text-center"
              md={{ size: 10, offset: 1 }}
              lg={{ size: 8, offset: 2 }}
            >
              <a href="https://thestudypro.com">
                <img
                  src="/logo.png"
                  alt="The StudyPro"
                  className="img-fluid w-100"
                />
              </a>
            </Col>
          </Row>
          <Row>
            <Col className="rounded bg-primary text-white shadow py-5 px-3">
              <h1 className="text-center text-white">
                Online Study Skills Self-Assessment
              </h1>
              <div className=" d-flex flex-column flex-md-row align-items-center justify-content-center">
                <div className="lead">
                  Take the online study skills assessment:
                </div>
                <div className="ml-3 mt-3" />
                <Button
                  href={routes.assessment}
                  className="shadow"
                  size="lg"
                  color="light"
                >
                  Take The Assessment
                </Button>
              </div>
            </Col>
          </Row>

          <Row className="mt-5">
            <Col md={6}>
              <div className="p-3 mb-3 bg-white rounded w-100 shadow-sm">
                <h4>Current Users</h4>
                <p>For instructors who already have an account.</p>
                <Button
                  href={routes.account.signin}
                  block
                  outline
                  color="primary"
                >
                  Sign In
                </Button>
              </div>
            </Col>
            <Col md={6}>
              <div className="mb-5 p-3 rounded bg-white w-100 shadow-sm">
                <h4>New Users</h4>
                <p>For instructors who need to create an account.</p>
                <Button href={routes.account.signup} block color="primary">
                  Sign Up
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  );
}
