import React from "react";

export default function AssessmentComplete() {
  return (
    <div className="text-center">
      <div className="display-4 mb-3">
        <span role="img" aria-label="">
          🎊
        </span>
      </div>
      <h1>Well Done</h1>
      <p>
        You've completed your online assessment.
        <br />
        We look forward to discussing your results with you in class!
      </p>
      <div className="mb-3" />
      <p>
        <em>You may now close this browser window.</em>
      </p>
    </div>
  );
}
