import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/performance";
import "firebase/firestore";
import "firebase/storage";

import * as Sentry from "@sentry/react";

import { firebase as firebaseConfig } from "./configuration";

if (process.env.NODE_ENV === "development") {
  console.log(firebaseConfig);
  import("./devtools");
}

try {
  firebase.initializeApp(firebaseConfig);
} catch (e) {
  console.error(`Error initializing firebase library`);
}

Sentry.init({
  dsn:
    "https://ced36eff781a48fa8e2738d7f5fafed6@o232675.ingest.sentry.io/5355470",
});

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
