import React, { useState } from "react";
import { Form, FormGroup, Label, Input } from "reactstrap";

import * as firebase from "firebase/app";

import useRouter from "../../hooks/useRouter";
import routes from "../../routes";
import Layout from "./Layout";
import useErrorProvider from "../../hooks/useErrorProvider";
import BusyButton from "../../components/BusyButton";

export default () => {
  const { reportError } = useErrorProvider();
  const { routeTo } = useRouter();

  const [isBusy, setBusy] = useState(false);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const onChangeName = (e) => {
    setName(e.target.value);
  };
  const onChangeEmail = (e) => {
    setEmail(e.target.value);
  };
  const onChangePassword = (e) => {
    setPassword(e.target.value);
  };
  const onSubmit = (e) => {
    e.preventDefault();
    setBusy(true);
    firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then(({ user }) => {
        user
          .updateProfile({
            displayName: name,
          })
          .then(() => routeTo(routes.dashboard));
      })
      .catch((error) => {
        reportError(error);
      })
      .finally(() => setBusy(false));
  };

  return (
    <Layout.Container>
      <h3>Sign Up</h3>
      <Form onSubmit={onSubmit}>
        <FormGroup>
          <Label for="name">Your Username</Label>
          <Input
            name="name"
            type="text"
            placeholder="Your name"
            value={name}
            onChange={onChangeName}
          />
        </FormGroup>
        <FormGroup>
          <Label for="email">Your Email</Label>
          <Input
            name="email"
            type="email"
            placeholder="Your email address"
            value={email}
            onChange={onChangeEmail}
          />
        </FormGroup>
        <FormGroup>
          <Label for="password">Your Password</Label>
          <Input
            name="password"
            type="password"
            placeholder="Your password"
            value={password}
            onChange={onChangePassword}
          />
        </FormGroup>
        <Layout.Footer>
          <div>
            Already have an acount?&nbsp;
            <a href={routes.account.signin}>Sign In</a>
          </div>
          <BusyButton type="submit" color="primary" isBusy={isBusy}>
            Sign Up
          </BusyButton>
        </Layout.Footer>
      </Form>
    </Layout.Container>
  );
};
