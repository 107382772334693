import { Chart } from "chart.js";
import "chartjs-plugin-datalabels";

import {
  map,
  get,
  size,
  groupBy,
  toNumber,
  isNumber,
  sortBy,
  keys,
} from "lodash";
import { ScoresMap, AssessmentResultRecordType, ScoreType } from "../types";

const BLUE = "rgba(66, 158, 216, 1.0)";
const ORANGE = "rgba(241, 160, 70, 1.0)";
const RED = "rgba(220, 53, 69, 1.0)";
const GREEN = "rgba(68, 187, 119, 1.0)";

function formatScore({ score }: ScoreType) {
  return toNumber(score.toFixed(1));
}

export function getChartPropsForExecutiveFunctionSkills(scores: ScoresMap) {
  const executiveFunctions = sortBy(get(scores, "EF Skill"), "score").reverse();

  const data = {
    labels: map(executiveFunctions, "group"),
    datasets: [
      {
        data: map(executiveFunctions, formatScore),
        backgroundColor: BLUE,
        borderColor: BLUE,
        borderWidth: 1,
        barThickness: 20,
      },
    ],
  };
  const options = {
    plugins: {
      datalabels: {
        color: "#8c8c8c",
        anchor: "end" as "end",
        align: "end" as "end",
      },
    },
    scales: {
      yAxes: [
        {
          gridLines: { display: false },
          ticks: {
            beginAtZero: true,
          },
        },
      ],
      xAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: "Points",
          },
          ticks: {
            beginAtZero: true,
            max: 25,
          },
        },
      ],
    },
    legend: { display: false },
    showLines: false,
  };

  const props = {
    type: "horizontalBar",
    data: data,
    options: options,
  };

  return props;
}

export function renderChartForExecutiveFunctionSkills(
  ctx: any,
  scores: ScoresMap
) {
  const props = getChartPropsForExecutiveFunctionSkills(scores);

  new Chart(ctx, props);
}

export function getChartPropsForStudySkills(scores: ScoresMap) {
  const studySkills = sortBy(get(scores, "Study Skill"), "score").reverse();

  const data = {
    labels: map(studySkills, "group"),
    datasets: [
      {
        data: map(studySkills, formatScore),
        backgroundColor: ORANGE,
        borderColor: ORANGE,
        borderWidth: 1,
        barThickness: 20,
      },
    ],
  };
  const options = {
    plugins: {
      datalabels: {
        color: "#8c8c8c",
        anchor: "end" as "end",
        align: "end" as "end",
      },
    },
    scales: {
      yAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: "Points",
          },
          ticks: {
            beginAtZero: true,
            max: 25,
          },
        },
      ],
      xAxes: [
        {
          gridLines: { display: false },
          ticks: {
            beginAtZero: true,
            maxRotation: 0,
            minRotation: 0,
          },
        },
      ],
    },
    legend: { display: false },
    showLines: false,
  };

  const props = {
    type: "bar",
    data: data,
    options: options,
  };

  return props;
}

export function renderChartForStudySkills(ctx: any, scores: ScoresMap) {
  const props = getChartPropsForStudySkills(scores);

  new Chart(ctx, props);
}

function getDurationGroup(durationInMinutes: number) {
  if (durationInMinutes < 5) {
    return "0-4 minutes";
  }
  if (durationInMinutes < 9) {
    return "5-9 minutes";
  }
  if (durationInMinutes < 13) {
    return "9-12 minutes";
  }
  return "13 or more";
}

export function getChartPropsForAssessmentDuration(
  assessments: Array<AssessmentResultRecordType>
) {
  const durationsInMillis = map(assessments, "durationInMillis").filter(
    (duration) => isNumber(duration) && !isNaN(duration)
  );
  const durationsInMinutes = groupBy(durationsInMillis, (duration) => {
    const minutes =
      isNumber(duration) && !isNaN(duration) ? duration / 60000 : NaN;
    return getDurationGroup(minutes);
  });

  const data = {
    labels: keys(durationsInMinutes),
    datasets: [
      {
        data: map(durationsInMinutes, size),
        backgroundColor: [RED, ORANGE, GREEN, BLUE],
        borderWidth: 2,
      },
    ],
  };
  const options = {
    plugins: {
      datalabels: {
        color: "#8c8c8c",
        anchor: "end" as "end",
        align: "end" as "end",
        formatter: (_value: number, context: any) =>
          context.chart.data.labels[context.dataIndex],
      },
    },
    legend: { display: false },
    showLines: false,
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 50,
        bottom: 50,
      },
    },
  };

  const props = {
    type: "doughnut",
    data: data,
    options: options,
  };

  return props;
}

export function renderChartForAssessmentDuration(
  ctx: any,
  assessments: Array<AssessmentResultRecordType>
) {
  const props = getChartPropsForAssessmentDuration(assessments);

  new Chart(ctx, props);
}
