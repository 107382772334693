import React, { FormEvent, ChangeEvent } from "react";
import { Form } from "reactstrap";
import { InputGroup, InputGroupAddon, InputGroupText, Input } from "reactstrap";
import { mdiMagnify } from "@mdi/js";
import Icon from "@mdi/react";

interface Props {
  query: string;
  onChange: (query: string) => void;
}

export default function AssessmentResultFilter(props: Props) {
  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) =>
    props.onChange(e.target.value);

  return (
    <Form onSubmit={onSubmit}>
      <InputGroup>
        <InputGroupAddon addonType="prepend">
          <InputGroupText>
            <Icon path={mdiMagnify} size={0.7} />
          </InputGroupText>
        </InputGroupAddon>
        <Input
          placeholder="Start typing to search..."
          value={props.query}
          onChange={onChange}
        />
      </InputGroup>
    </Form>
  );
}
