import React, { useState } from "react";
import { Form } from "reactstrap";

import routes from "../../routes";
import Layout from "./Layout";
import useErrorProvider from "../../hooks/useErrorProvider";
import useAccountProfile from "../../hooks/useAccountProfile";
import useUpdateAccountProfile from "../../hooks/useUpdateAccountProfile";
import BusyButton from "../../components/BusyButton";
import useRouter from "../../hooks/useRouter";

export default function Profile() {
  const { reportError } = useErrorProvider();
  const { routeTo } = useRouter();

  // eslint-disable-next-line no-unused-vars
  const profile = useAccountProfile();
  const updateAccountProfile = useUpdateAccountProfile();

  const [isBusy, setBusy] = useState(false);

  const onSubmit = (e) => {
    e.preventDefault();

    setBusy(true);

    updateAccountProfile({})
      .catch((error) => {
        reportError(error);
      })
      .finally(() => {
        setBusy(false);
        routeTo(routes.dashboard);
      });
  };

  return (
    <Layout.Container>
      <h3>Your Profile</h3>
      <Form onSubmit={onSubmit}>
        <Layout.Footer>
          <div />
          <div>
            <BusyButton
              type="submit"
              color="success"
              isBusy={isBusy}
              className="shadow-sm"
            >
              Continue
            </BusyButton>
          </div>
        </Layout.Footer>
      </Form>
    </Layout.Container>
  );
}
