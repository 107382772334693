import React, { FormEvent } from "react";
import { Form, FormGroup, Label, Input, FormText, Button } from "reactstrap";
import { get, isEmpty, startCase } from "lodash";
import useFormInputState from "../hooks/useFormInputState";
import { StudentInformationType } from "../types";

interface Props {
  initialStudentInformation?: StudentInformationType;
  onSubmit: (info: StudentInformationType) => void;
}

export default function StudentInformationForm(props: Props) {
  const [firstName, onChangeFirstName] = useFormInputState(
    get(props.initialStudentInformation, "firstName")
  );
  const [lastName, onChangeLastName] = useFormInputState(
    get(props.initialStudentInformation, "lastName")
  );
  const [email, onChangeEmail] = useFormInputState(
    get(props.initialStudentInformation, "email")
  );
  const [grade, onChangeGrade] = useFormInputState(
    get(props.initialStudentInformation, "grade", "")
  );
  const [passcode, onChangePasscode] = useFormInputState(
    get(props.initialStudentInformation, "passcode", "")
  );

  const canSubmit =
    !isEmpty(firstName) &&
    !isEmpty(lastName) &&
    !isEmpty(grade) &&
    !isEmpty(passcode);

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    props.onSubmit({
      name: `${startCase(firstName)} ${startCase(lastName)}`,
      email,
      grade,
      passcode,
    });
  };

  return (
    <Form onSubmit={onSubmit}>
      <FormGroup>
        <Label for="firstName">First Name</Label>
        <Input
          name="firstName"
          type="text"
          placeholder="Please enter your first name"
          value={firstName}
          onChange={onChangeFirstName}
        />
      </FormGroup>

      <FormGroup>
        <Label for="lastName">Last Name</Label>
        <Input
          name="lastName"
          type="text"
          placeholder="Please enter your last name"
          value={lastName}
          onChange={onChangeLastName}
        />
      </FormGroup>

      <FormGroup>
        <Label for="email">Email</Label>
        <Input
          name="email"
          type="email"
          placeholder="Please enter your email"
          value={email}
          onChange={onChangeEmail}
        />
      </FormGroup>

      <FormGroup>
        <Label for="grade">Grade</Label>
        <Input
          name="grade"
          type="select"
          value={grade}
          onChange={onChangeGrade}
        >
          <option value=""></option>
          <option>4th Grade</option>
          <option>5th Grade</option>
          <option>6th Grade</option>
          <option>7th Grade</option>
          <option>8th Grade</option>
          <option>9th Grade</option>
          <option>10th Grade</option>
          <option>11th Grade</option>
          <option>12th Grade</option>
          <option>College</option>
          <option>Other</option>
        </Input>
      </FormGroup>

      <FormGroup>
        <Label for="code">Assessment Code</Label>
        <Input
          name="code"
          type="text"
          value={passcode}
          onChange={onChangePasscode}
        />
        <FormText>
          Your assessment code will be provided to you by your course
          instructor.
        </FormText>
      </FormGroup>

      <div className="text-center mt-5">
        <Button
          type="submit"
          color="success"
          size="lg"
          className="shadow"
          disabled={!canSubmit}
        >
          Take The Self-Assessment
        </Button>
      </div>
    </Form>
  );
}
