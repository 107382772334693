import React from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";

import "./App.css";
import "@the-study-pro/theme/dist/bootstrap.css";

import { ErrorProvider } from "./hooks/useErrorProvider";
import { NotificationProvider } from "./components/Notification";

import Route from "./components/helpers/Route";
import ProtectedRoute from "./components/helpers/ProtectedRoute";

import routes from "./routes";

import Styleguide from "./pages/styleguide";
import Landing from "./pages/Landing";
import Assessment from "./pages/assessment";
import Report from "./pages/styleguide/report";
import AccountSignUp from "./pages/account/SignUp";
import AccountSignIn from "./pages/account/SignIn";
import AccountSignOut from "./pages/account/SignOut";
import AccountRecover from "./pages/account/Recover";
import AccountResetPassword from "./pages/account/ResetPassword";
import AccountProfile from "./pages/account/Profile";
import AccountLoading from "./pages/account/Loading";
import AccountAction from "./pages/account/Action";
import Dashboard from "./pages/Dashboard";
import Redirect from "./pages/Redirect";
import SupportDashboard from "./pages/support/Dashboard";
import SupportAccounts from "./pages/support/Accounts";
import Courses from "./pages/support/Courses";
import Analysis from "./pages/support/Analysis";
import NotFound from "./pages/NotFound";

import { RouterConnection } from "./hooks/useRouter";
import { SupportProfileProvider } from "./hooks/useSupportProfile";
import ScrollRestoration from "./components/helpers/ScrollRestoration";

import Footer from "./components/Footer";

function App() {
  return (
    <NotificationProvider>
      <ErrorProvider>
        <SupportProfileProvider>
          <Router>
            <RouterConnection />
            <ScrollRestoration />

            <Switch>
              <Route exact path={routes.landing}>
                <Landing />
              </Route>

              <ProtectedRoute exact path={routes.dashboard}>
                <Dashboard />
              </ProtectedRoute>

              <Route exact path={routes.account.signup}>
                <AccountSignUp />
              </Route>
              <Route path={routes.account.signin}>
                <AccountSignIn />
              </Route>
              <Route exact path={routes.account.signout}>
                <AccountSignOut />
              </Route>
              <Route exact path={routes.account.recover}>
                <AccountRecover />
              </Route>
              <Route exact path={routes.account.reset}>
                <AccountResetPassword />
              </Route>
              <Route exact path={routes.account.action}>
                <AccountAction />
              </Route>

              <ProtectedRoute exact path={routes.account.loading}>
                <AccountLoading />
              </ProtectedRoute>
              <ProtectedRoute exact path={routes.account.profile}>
                <AccountProfile />
              </ProtectedRoute>

              <ProtectedRoute exact path={routes.support.dashboard}>
                <SupportDashboard />
              </ProtectedRoute>
              <ProtectedRoute exact path={routes.support.accounts}>
                <SupportAccounts />
              </ProtectedRoute>
              <ProtectedRoute exact path={routes.support.analysis}>
                <Analysis />
              </ProtectedRoute>
              <ProtectedRoute exact path={routes.support.courses}>
                <Courses />
              </ProtectedRoute>

              <Route exact path={routes.assessment}>
                <Assessment />
              </Route>

              <Route exact path={routes.styleguide}>
                <Styleguide />
              </Route>

              <Route exact path={routes.report}>
                <Report />
              </Route>

              <Route exact path={routes.redirect()}>
                <Redirect />
              </Route>

              <Route path="*">
                <NotFound />
              </Route>
            </Switch>
          </Router>

          <Footer />
        </SupportProfileProvider>
      </ErrorProvider>
    </NotificationProvider>
  );
}

export default App;
