import React from "react";
import { Nav, NavItem, NavLink } from "reactstrap";
import routes from "../routes";

interface Props {
  active: "dashboard" | "analysis" | "courses";
}

export default function SupportNavigation({ active }: Props) {
  return (
    <Nav pills>
      <NavItem>
        <NavLink
          href={routes.support.dashboard}
          active={active === "dashboard"}
        >
          Assessments
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink href={routes.support.analysis} active={active === "analysis"}>
          Analysis
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink href={routes.support.courses} active={active === "courses"}>
          Courses
        </NavLink>
      </NavItem>
    </Nav>
  );
}
