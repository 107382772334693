import * as firebase from "firebase/app";
import useAuth from "./useAuth";
import useErrorProvider from "./useErrorProvider";
import { useState, useEffect } from "react";
import getDataFromDocuments from "../utils/getDataFromDocuments";
import useSupportProfile from "./useSupportProfile";
import { CoursesMap } from "../types";
import keyCoursesByPasscode from "../utils/keyCoursesByPasscode";

type HookReturnValue = [CoursesMap, boolean];

export default function useAllCourses(): HookReturnValue {
  const [isLoading, setLoading] = useState(false);
  const [courses, setCourses] = useState({});
  const { user } = useAuth();
  const supportProfile = useSupportProfile();
  const { reportError } = useErrorProvider();

  useEffect(() => {
    setLoading(true);
    const db = firebase.firestore();
    return db.collection("course").onSnapshot({
      next(result) {
        setLoading(false);
        setCourses(keyCoursesByPasscode(getDataFromDocuments(result.docs)));
      },
      error: (e) => reportError(e, "useAllCourses"),
    });
  }, [reportError, user, supportProfile]);

  return [courses, isLoading];
}
