import React from "react";
import { get } from "lodash";

import Question from "./Question";
import { AnswersMap, QuestionType } from "../types";
import classes from "../utils/classes";

type OnChange = (index: number, value: number) => void;

interface Props {
  questions: QuestionType[];
  onChange: OnChange;
  values: AnswersMap;
}

export default function Questions(props: Props) {
  return (
    <div>
      {props.questions.map((question, position) => (
        <div
          key={question.index}
          className={classes({ "bg-light": position % 2 === 0 }, "p-4")}
        >
          <Question
            index={question.index}
            text={question.text}
            value={get(props.values, [question.index, "score"])}
            onChange={(newScore: number) => {
              props.onChange(question.index, newScore);
            }}
          />
        </div>
      ))}
    </div>
  );
}
