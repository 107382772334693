import React from "react";
import { Button, ButtonGroup } from "reactstrap";

type OnChange = (value: number) => void;

interface Props {
  /** Zero-based index for this question */
  index: number;
  text: string;
  onChange: OnChange;
  value?: number;
}

const buttonProps = (
  score: number,
  value: number | undefined,
  onChange: OnChange
) => ({
  color: "primary",
  outline: true,
  active: score === value,
  onClick() {
    onChange(score);
  },
});

const Scale = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className: string;
}) => <small className={`text-muted ${className}`}>{children}</small>;

export default function Question({ index, text, value, onChange }: Props) {
  return (
    <div className="d-flex flex-column flex-md-row align-items-stretch align-items-md-center justify-content-between">
      <div className="d-flex align-items-center">
        <div className="text-muted mr-3">{index + 1}.</div>
        {text}
      </div>
      <div className="ml-3 mt-3" />
      <div className="d-flex align-items-center justify-content-center">
        <Scale className="mr-2">always</Scale>
        <ButtonGroup size="sm">
          <Button {...buttonProps(1, value, onChange)}>1</Button>
          <Button {...buttonProps(2, value, onChange)}>2</Button>
          <Button {...buttonProps(3, value, onChange)}>3</Button>
          <Button {...buttonProps(4, value, onChange)}>4</Button>
          <Button {...buttonProps(5, value, onChange)}>5</Button>
        </ButtonGroup>
        <Scale className="ml-2">never</Scale>
      </div>
    </div>
  );
}
