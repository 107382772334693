import React from "react";
import Layout from "./Layout";
import { Button, Container, Row, Col } from "reactstrap";

import routes from "../routes";
import IfSupport from "../components/helpers/IfSupport";

export default function Dashboard() {
  return (
    <Layout>
      <div className="min-vh-100 d-flex align-items-center justify-content-center">
        <Container>
          <Row>
            <Col
              className="text-center"
              md={{ size: 10, offset: 1 }}
              lg={{ size: 8, offset: 2 }}
            >
              <a href="https://thestudypro.com">
                <img
                  src="/logo.png"
                  alt="The StudyPro"
                  className="img-fluid w-100"
                />
              </a>
            </Col>
          </Row>
          <Row>
            <Col className="mb-5 rounded bg-primary text-white shadow py-5 px-3">
              <h1 className="text-center text-white">
                Online Study Skills Self-Assessment
              </h1>
              <div className=" d-flex flex-column flex-md-row align-items-center justify-content-center">
                <div className="lead">
                  Take the online study skills assessment:
                </div>
                <div className="ml-3 mt-3" />
                <Button
                  href={routes.assessment}
                  className="shadow"
                  size="lg"
                  color="light"
                >
                  Take The Assessment
                </Button>
              </div>
            </Col>
          </Row>
          <IfSupport>
            <Row>
              <Col className="mb-5 rounded bg-success text-white shadow py-5 px-3">
                <h1 className="text-center text-white">Instructor Dashboard</h1>
                <div className=" d-flex flex-column flex-md-row align-items-center justify-content-center">
                  <div className="lead">
                    Manage student assessments and analyze scores:
                  </div>
                  <div className="ml-3 mt-3" />
                  <Button
                    href={routes.support.dashboard}
                    className="shadow"
                    size="lg"
                    color="light"
                  >
                    Go to the Instructor Dashboard
                  </Button>
                </div>
              </Col>
            </Row>
          </IfSupport>
        </Container>
      </div>
    </Layout>
  );
}
