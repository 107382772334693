import React, { Fragment, useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  Table,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import Icon from "@mdi/react";
import {
  mdiAccountGroup,
  mdiMagnify,
  mdiMenuDown,
  mdiShareVariant,
} from "@mdi/js";
import { filter, map, isEmpty } from "lodash";

import Layout from "../Layout";
import If from "../../components/helpers/If";
import useAllCourses from "../../hooks/useAllCourses";
import isMatchingCourse from "../../utils/isMatchingCourse";
import toggle from "../../utils/toggle";
import AddCourseForm from "../../components/AddCourseForm";
import useAddCourse from "../../hooks/useAddCourse";
import { useNotification } from "../../components/Notification";
import CourseRenameModal from "../../components/CourseRenameModal";
import CourseDeleteModal from "../../components/CourseDeleteModal";
import useDeleteCourse from "../../hooks/useDeleteCourse";
import useUpdateCourse from "../../hooks/useUpdateCourse";
import SupportNavigation from "../../components/SupportNavigation";
import AssessmentSharingDetails from "../../components/AssessmentSharingDetails";

function Course(props) {
  const { course } = props;

  const updateCourse = useUpdateCourse();
  const [isRenaming, setRenaming] = useState(false);

  const onToggleRenaming = () => setRenaming(toggle);
  const onRenameCourse = () => setRenaming(true);
  const onCancelRename = () => setRenaming(false);
  const onPerformRename = async (newCourseName) => {
    await updateCourse(course.id, { name: newCourseName });
    setRenaming(false);
  };

  const deleteCourse = useDeleteCourse();
  const [isConfirmingDelete, setConfirmingDelete] = useState(false);

  const onToggleConfirmingDelete = () => setConfirmingDelete(toggle);
  const onDeleteCourse = (e) => setConfirmingDelete(true);
  const onCancelDelete = () => setConfirmingDelete(false);
  const onPerformDelete = async () => {
    await deleteCourse(course.id);
    setConfirmingDelete(false);
  };

  const [isSharing, setSharing] = useState(false);
  const onToggleSharing = () => setSharing(toggle);
  const onShare = () => setSharing(true);

  return (
    <Fragment>
      <tr>
        <td className="my-2">
          <Icon path={mdiAccountGroup} size={1} />
        </td>
        <td>{course.name}</td>
        <td className="text-monospace">{course.passcode}</td>
        <td>
          <code>{course.id}</code>
        </td>
        <td>
          <span className="d-flex justify-content-end">
            <Button size="sm" outline color="dark" onClick={onShare}>
              <Icon path={mdiShareVariant} />
            </Button>
            <div className="ml-2" />
            <UncontrolledDropdown>
              <DropdownToggle outline color="dark" size="sm">
                <Icon path={mdiMenuDown} size={0.7} />
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={onRenameCourse}>Rename</DropdownItem>
                <DropdownItem divider />
                <DropdownItem onClick={onDeleteCourse}>Delete</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </span>
        </td>
      </tr>
      <Modal isOpen={isRenaming} toggle={onToggleRenaming} autoFocus>
        <CourseRenameModal
          courseName={course.name}
          onCancel={onCancelRename}
          onRename={onPerformRename}
        />
      </Modal>
      <Modal
        isOpen={isConfirmingDelete}
        toggle={onToggleConfirmingDelete}
        autoFocus
      >
        <CourseDeleteModal
          courseName={course.name}
          onCancel={onCancelDelete}
          onDelete={onPerformDelete}
        />
      </Modal>
      <Modal size="lg" isOpen={isSharing} toggle={onToggleSharing} autoFocus>
        <ModalHeader toggle={onToggleSharing}>
          Share Course Assessment
        </ModalHeader>
        <AssessmentSharingDetails course={course} />
      </Modal>
    </Fragment>
  );
}

function FilterForm(props) {
  const onSubmit = (e) => e.preventDefault();

  const onChange = (e) => props.onChangeFilter(e.target.value);

  return (
    <Form onSubmit={onSubmit}>
      <InputGroup>
        <Input
          type="text"
          placeholder="Type to filter courses..."
          onChange={onChange}
        />
        <InputGroupAddon addonType="append">
          <InputGroupText>
            <Icon path={mdiMagnify} size={0.7} />
          </InputGroupText>
        </InputGroupAddon>
      </InputGroup>
    </Form>
  );
}

export default function Courses() {
  const [courses, isLoading] = useAllCourses();
  const [filterText, setFilterText] = useState("");

  const coursesToRender = filter(courses, (course) =>
    isMatchingCourse(course, filterText)
  );

  const [isAddingCourse, setAddingCourse] = useState(false);

  const onAddCourse = (e) => {
    setAddingCourse(true);
  };

  const onCancelAddingCourse = () => {
    setAddingCourse(false);
  };

  const onToggleAddingCourse = () => {
    setAddingCourse((state) => !state);
  };

  const { notify } = useNotification();
  const addCourse = useAddCourse();

  const onPerformCourseAdd = async (name, passcode) => {
    await addCourse({ name, passcode });
    setAddingCourse(false);
    notify(`${name} added successfully`, "success");
  };

  return (
    <Fragment>
      <Layout>
        <Container>
          <Row className="mt-5">
            <Col>
              <div className="d-flex flex-column flex-md-row align-items-center justify-content-between">
                <h1 className="m-0 h3">Manage Courses</h1>
                <SupportNavigation active="courses" />
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col className="rounded p-3 bg-white shadow">
              <div className="d-flex flex-column flex-md-row mb-3 align-items-center">
                <div className="mr-auto">
                  <FilterForm onChangeFilter={setFilterText} />
                </div>
                <div>
                  <Button color="success" onClick={onAddCourse}>
                    Add Course
                  </Button>
                </div>
              </div>
              <Table responsive striped>
                <thead>
                  <tr>
                    <th style={{ width: 50 }} />
                    <th>Course Name</th>
                    <th>Passcode</th>
                    <th>Course ID</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {map(coursesToRender, (course, index) => (
                    <Course key={index} course={course} />
                  ))}
                  <If test={isEmpty(coursesToRender)}>
                    <tr>
                      <td colSpan="5" className="text-center">
                        <em>All courses are filtered</em>
                      </td>
                    </tr>
                  </If>
                </tbody>
              </Table>

              <If test={isLoading}>
                <div className="text-center my-5">
                  <Spinner color="primary" size="1" />
                </div>
              </If>
            </Col>
          </Row>
        </Container>
      </Layout>

      <Modal isOpen={isAddingCourse} toggle={onToggleAddingCourse}>
        <ModalHeader toggle={onToggleAddingCourse}>Add A Course</ModalHeader>
        <ModalBody>
          <AddCourseForm
            onCancel={onCancelAddingCourse}
            onAddCourse={onPerformCourseAdd}
          />
        </ModalBody>
      </Modal>
    </Fragment>
  );
}
