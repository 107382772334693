import React, { Fragment } from "react";

import Navigation from "../components/Navigation";

interface Props {
  children: React.ReactChild;
}

export default function Layout({ children }: Props) {
  return (
    <Fragment>
      <Navigation />
      {children}
    </Fragment>
  );
}
