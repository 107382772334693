import { mdiAlertOctagon } from "@mdi/js";
import Icon from "@mdi/react";
import React, { FormEvent } from "react";
import { Button, Form } from "reactstrap";

interface Props {
  onCancel: () => void;
  onDelete: () => void;
}

export default function DeleteConfirmation(props: Props) {
  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    props.onDelete();
  };

  return (
    <Form onSubmit={onSubmit}>
      <div className="d-flex align-items-center text-danger border border-danger mb-3 p-2 rounded">
        <Icon path={mdiAlertOctagon} size={0.7} className="mr-2" />
        <strong>Deleting this record cannot be undone.</strong>
      </div>
      <div className="d-flex justify-content-center">
        <Button type="submit" color="danger" block>
          I understand, delete this record
        </Button>
      </div>
    </Form>
  );
}
