import { get } from "lodash";
import { AssessmentResultRecordType, CoursesMap } from "../types";

const UNKNOWN_COURSE = {
  name: "unknown",
  passcode: "",
};

export default function getAssessmentCourse(
  courses: CoursesMap,
  assessment: AssessmentResultRecordType
) {
  return get(courses, assessment.student.passcode, UNKNOWN_COURSE);
}
