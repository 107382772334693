import * as _ from "lodash";
import { AnswersMap, QuestionType, ScoresMap } from "../types";

// TODO add unit tests
export default function scoreSubmittedAnswers(
  questions: QuestionType[],
  answers: AnswersMap
): ScoresMap {
  return _.chain(answers)
    .map((answer) => ({
      ..._.get(questions, answer.index),
      ...answer,
    }))
    .groupBy("category")
    .mapValues((categoryAnswers) =>
      _.chain(categoryAnswers)
        .groupBy("group")
        .map((groupAnswers, group) => ({
          group,
          score: _.sumBy(groupAnswers, "score"),
        }))
        .sortBy("score")
        .reverse()
        .value()
    )
    .value();
}
