import React, { Fragment, useEffect, useRef } from "react";
import {
  map,
  flatMap,
  isEmpty,
  groupBy,
  mapValues,
  meanBy,
  size,
} from "lodash";
import { Container, Row, Col, Spinner } from "reactstrap";

import Layout from "../Layout";
import If from "../../components/helpers/If";
import useAllAssessmentResults from "../../hooks/useAllAssessmentResults";
import SupportNavigation from "../../components/SupportNavigation";
import {
  renderChartForExecutiveFunctionSkills,
  renderChartForStudySkills,
  renderChartForAssessmentDuration,
} from "../../components/Charts";
import { ScoresMap } from "../../types";

export default function Dashboard() {
  const [assessments, isLoading] = useAllAssessmentResults();

  const individualScores = map(
    assessments,
    "scores"
  ).flatMap((scoresByCategory) =>
    flatMap(scoresByCategory, (scores, category) =>
      flatMap(scores, (score) => ({ ...score, category }))
    )
  );

  const aggregateScores: ScoresMap = mapValues(
    groupBy(individualScores, "category"),
    (byCategory) =>
      map(groupBy(byCategory, "group"), (scoresForGroup, group) => ({
        group,
        score: meanBy(scoresForGroup, "score"),
      }))
  );
  const hasAggregateScores = !isEmpty(aggregateScores);

  const averageDurationInMinutes = (
    meanBy(assessments, "durationInMillis") / 60000
  ).toFixed(1);

  const chart1Ref = useRef<HTMLCanvasElement>(null);
  const chart2Ref = useRef<HTMLCanvasElement>(null);
  const chart3Ref = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    console.log({ assessments, aggregateScores });
    if (chart1Ref.current && hasAggregateScores) {
      const ctx = chart1Ref.current.getContext("2d");
      renderChartForExecutiveFunctionSkills(ctx, aggregateScores);
    }
    if (chart2Ref.current && hasAggregateScores) {
      const ctx = chart2Ref.current.getContext("2d");
      renderChartForStudySkills(ctx, aggregateScores);
    }
    if (chart3Ref.current && hasAggregateScores) {
      const ctx = chart3Ref.current.getContext("2d");
      renderChartForAssessmentDuration(ctx, assessments);
    }
  }, [aggregateScores, hasAggregateScores, assessments]);

  return (
    <Fragment>
      <Layout>
        <Container>
          <Row className="mt-5">
            <Col>
              <div className="d-flex flex-column flex-md-row align-items-center justify-content-between">
                <h1 className="m-0 h3">Analysis</h1>
                <SupportNavigation active="analysis" />
              </div>
            </Col>
          </Row>

          <Row className="mt-3">
            <Col className="rounded p-3 bg-white shadow">
              <If test={!isLoading && !isEmpty(assessments)}>
                <h2 className="text-center">Executive Function Scoring</h2>
                <div className="w-100 mb-5">
                  <canvas
                    ref={chart1Ref}
                    style={{ width: "100%", height: 400 }}
                  />
                </div>

                <h2 className="text-center">Study Skills Scoring</h2>
                <div className="w-100 mb-5">
                  <canvas
                    ref={chart2Ref}
                    style={{ width: "100%", height: 300 }}
                  />
                </div>

                <h2 className="text-center">Assessment Duration</h2>
                <div className="d-md-flex align-items-center">
                  <div className="text-center w-100">
                    Total assessments taken:{" "}
                    <strong>{size(assessments)}</strong>
                  </div>
                  <div className="text-center w-100">
                    Average assessment duration:{" "}
                    <strong>{averageDurationInMinutes} minutes</strong>
                  </div>
                </div>

                <div className="w-100">
                  <canvas
                    ref={chart3Ref}
                    style={{ width: "100%", height: 400 }}
                  />
                </div>
              </If>

              <If test={isEmpty(assessments) && !isLoading}>
                <div className="m-5 d-flex align-items-center justify-content-center">
                  <div className="px-3">No assessment records available.</div>
                </div>
              </If>

              <If test={isLoading}>
                <div className="m-5 d-flex align-items-center justify-content-center">
                  <div className="px-3">Loading student assessment records</div>
                  <Spinner color="primary" />
                </div>
              </If>
            </Col>
          </Row>
        </Container>
      </Layout>
    </Fragment>
  );
}
