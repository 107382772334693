import * as firebase from "firebase/app";
import { SERVICE_ROOT_URL } from "../configuration";
import { defer } from "lodash";
import useErrorProvider from "./useErrorProvider";
import { useCallback, useEffect, useState } from "react";

export default function useAllAccounts() {
  const [isLoading, setLoading] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const { reportError } = useErrorProvider();

  const [refreshToken, setRefreshToken] = useState();

  useEffect(() => {
    defer(async () => {
      try {
        setLoading(true);

        const idToken = await firebase
          .auth()
          .currentUser.getIdToken(/* forceRefresh */ true);

        const result = await fetch(`${SERVICE_ROOT_URL}/accounts`, {
          method: "GET",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            "Authorization-Token": idToken
          }
        });

        const json = await result.json();

        setAccounts(json.users);
      } catch (e) {
        reportError(e);
      } finally {
        setLoading(false);
      }
    });
  }, [reportError, refreshToken]);

  const refreshAccounts = useCallback(() => setRefreshToken(Date.now()), [
    setRefreshToken
  ]);

  return [accounts, isLoading && !refreshToken, refreshAccounts];
}
