import { pick, some, trim, toLower, isEmpty, values, includes } from "lodash";
import { CourseType } from "../types";

const SEARCHABLE_PROPS = ["name"];

export default function isMatchingCourse(course: CourseType, query: string) {
  if (isEmpty(trim(query))) {
    return true;
  }
  return some(values(pick(course, SEARCHABLE_PROPS)), (val) =>
    includes(toLower(trim(val)), toLower(trim(query)))
  );
}
