import React, { FormEvent, useState } from "react";
import { Button, Form, FormGroup, Label, Input, FormText } from "reactstrap";
import BusyButton from "./BusyButton";
import useFormInputState from "../hooks/useFormInputState";

interface Props {
  onAddCourse: (courseName: string, passcode: string) => Promise<void>;
  onCancel: () => void;
}

export default function AddCourseForm({ onAddCourse, onCancel }: Props) {
  const [isBusy, setBusy] = useState(false);
  const [courseName, onChangeCourseName] = useFormInputState("");
  const [passcode, onChangePasscode] = useFormInputState("");

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();

    setBusy(true);

    onAddCourse(courseName, passcode).finally(() => setBusy(false));
  };

  return (
    <Form onSubmit={onSubmit}>
      <FormGroup>
        <Label for="course-name">Course name</Label>
        <Input
          type="text"
          id="course-name"
          placeholder="Please name the course"
          onChange={onChangeCourseName}
          value={courseName}
        />
      </FormGroup>

      <FormGroup>
        <Label for="passcode">Passcode</Label>
        <Input
          type="text"
          id="passcode"
          placeholder="Please provide a passcode"
          onChange={onChangePasscode}
          value={passcode}
        />
        <FormText>
          Students will use this passcode when taking the online
          self-assessment.
        </FormText>
      </FormGroup>

      <div className="pt-3 d-flex">
        <BusyButton
          type="submit"
          color="success"
          className="ml-auto"
          isBusy={isBusy}
        >
          Add Course
        </BusyButton>
        <Button color="secondary" className="ml-3" onClick={onCancel}>
          Cancel
        </Button>
      </div>
    </Form>
  );
}
