import React from "react";
import { Button, Spinner } from "reactstrap";
import { isEmpty } from "lodash";

const size = "1rem";

export default function BusyButton({
  isBusy,
  busyLabel = "",
  children,
  ...props
}) {
  const busyContent = !isEmpty(busyLabel) ? (
    <span>{busyLabel}&hellip;</span>
  ) : (
    <Spinner style={{ width: size, height: size }} color="currentColor" />
  );
  return (
    <Button {...props} disabled={isBusy || props.disabled}>
      {isBusy ? busyContent : children}
    </Button>
  );
}
