import React, { Fragment, useState } from "react";
import { Collapse, Button, ButtonGroup } from "reactstrap";
import { find, get, groupBy, isEmpty, map, sortBy, split, trim } from "lodash";

import { AnswersMap, AnswerType, QuestionType } from "../types";
import toggle from "../utils/toggle";
import Icon from "@mdi/react";
import { mdiChevronDown, mdiChevronUp } from "@mdi/js";
import classes from "../utils/classes";

interface Props {
  // Some assessments will not have this information
  // because it hasn't always been persisted to firebase
  questions?: QuestionType[];
  answers?: AnswersMap;
}

function AssessmentReviewUnavailable() {
  return (
    <div className="d-flex align-items-center">
      <em>
        Detailed assessment results were not recorded for this student and are
        therefore unavailable.
      </em>
    </div>
  );
}

function getGroupingKey(question: QuestionType) {
  return `${question.category}, ${question.group}`;
}

function parseGroupKey(key: string) {
  const [category, group] = split(key, ",").map(trim);
  return { category, group };
}

const Scale = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className: string;
}) => <small className={`text-muted ${className}`}>{children}</small>;

interface QuestionReviewProps {
  question: QuestionType;
  answer: AnswerType | undefined;
}

const buttonProps = (score: number, value: number | undefined) => ({
  color: "primary",
  outline: !(score === value),
  disabled: true,
});

function QuestionReview({ question, answer }: QuestionReviewProps) {
  const value = get(answer, "score");
  return (
    <div className="d-flex align-items-center justify-content-between">
      <div className="mr-3">{question.text}</div>
      <div className="d-flex align-items-center justify-content-center">
        <Scale className="mr-2">always</Scale>
        <ButtonGroup size="sm">
          <Button {...buttonProps(1, value)}>1</Button>
          <Button {...buttonProps(2, value)}>2</Button>
          <Button {...buttonProps(3, value)}>3</Button>
          <Button {...buttonProps(4, value)}>4</Button>
          <Button {...buttonProps(5, value)}>5</Button>
        </ButtonGroup>
        <Scale className="ml-2">never</Scale>
      </div>
    </div>
  );
}

interface QuestionGroupReviewProps {
  /** The category/group name */
  name: string;
  /** The questions in the category/group */
  questions: QuestionType[];
  /** All answers */
  answers: AnswersMap;
}

function QuestionGroupName({ name }: { name: string }) {
  const { category, group } = parseGroupKey(name);
  return (
    <h6 className="m-0">
      <small>
        <em className="text-muted">{category}</em>
      </small>
      &nbsp;
      {group}
    </h6>
  );
}

function QuestionGroupReview({
  name,
  questions,
  answers,
}: QuestionGroupReviewProps) {
  const [isOpen, setOpen] = useState(false);
  const onToggle = () => setOpen(toggle);
  return (
    <div className="border-bottom border-0-when-last p-3">
      <div
        role="button"
        tabIndex={0}
        onClick={onToggle}
        className="d-flex align-items-center justify-content-between"
      >
        <QuestionGroupName name={name} />
        <Icon
          path={isOpen ? mdiChevronUp : mdiChevronDown}
          size={0.7}
          className="ml-3"
        />
      </div>
      <Collapse isOpen={isOpen}>
        {map(questions, (question, index) => (
          <div
            className={classes({ "bg-light": index % 2 }, "p-2")}
            key={index}
          >
            <QuestionReview
              question={question}
              answer={find(
                answers,
                (answer) => answer.index === question.index
              )}
            />
          </div>
        ))}
      </Collapse>
    </div>
  );
}

interface AssessmentReviewDetailsProps {
  questions: QuestionType[];
  answers: AnswersMap;
}

function AssessmentReviewDetails({
  questions,
  answers,
}: AssessmentReviewDetailsProps) {
  const grouped = groupBy(sortBy(questions, getGroupingKey), getGroupingKey);
  return (
    <Fragment>
      {map(grouped, (group, key) => (
        <QuestionGroupReview
          key={key}
          name={key}
          questions={group}
          answers={answers}
        />
      ))}
    </Fragment>
  );
}

export default function AssessmentReview({ questions, answers }: Props) {
  const isReviewAvailable = isEmpty(questions) || isEmpty(answers);
  return isReviewAvailable ? (
    <AssessmentReviewUnavailable />
  ) : (
    <AssessmentReviewDetails
      questions={questions || []}
      answers={answers || []}
    />
  );
}
