import { isEmpty, filter, toLower, includes } from "lodash";
import { AssessmentResultRecordType, CoursesMap } from "../types";
import getStudentName from "./getStudentName";
import getAssessmentCourseName from "./getAssessmentCourseName";
import getAssessmentCoursePasscode from "./getAssessmentCoursePasscode";

function isMatchingAssessment(
  assessment: AssessmentResultRecordType,
  courses: CoursesMap,
  query: string
) {
  return (
    includes(toLower(getStudentName(assessment)), query) ||
    includes(toLower(getAssessmentCourseName(courses, assessment)), query) ||
    includes(toLower(getAssessmentCoursePasscode(assessment)), query)
  );
}

export default function filterAssessmentResults(
  assessments: AssessmentResultRecordType[],
  courses: CoursesMap,
  query: string
) {
  const sanitizedQuery = (query || "").trim().toLowerCase();
  if (isEmpty(sanitizedQuery)) {
    return assessments;
  }
  return filter(assessments, (assessment) =>
    isMatchingAssessment(assessment, courses, sanitizedQuery)
  );
}
