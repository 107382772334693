import * as firebase from "firebase/app";
import useAuth from "./useAuth";
import useErrorProvider from "./useErrorProvider";
import { useState, useEffect } from "react";
import getDataFromDocument from "../utils/getDataFromDocument";

export const getProfile = async user => {
  const db = firebase.firestore();
  const doc = await db
    .collection("profile")
    .doc(user.uid)
    .get();
  return getDataFromDocument(doc);
};

export default function useAccountProfile() {
  const [profile, setProfile] = useState();
  const { user } = useAuth();
  const { reportError } = useErrorProvider();

  useEffect(() => {
    const db = firebase.firestore();
    db.collection("profile")
      .doc(user.uid)
      .get()
      .then(doc => {
        setProfile(getDataFromDocument(doc));
      })
      .catch(e => reportError(e, "useAccountProfile"));
  }, [reportError, user]);

  return profile;
}
