import { useEffect, useState } from "react";
import { isEmpty } from "lodash";
import * as firebase from "firebase/app";

/**
 * Hook to act as a facade for all authentication-based logic
 */
export default () => {
  const auth = firebase.auth();

  const [isLoading, setLoading] = useState(true);
  const [currentUser, setCurrentUser] = useState(auth && auth.currentUser);

  useEffect(
    () =>
      auth &&
      auth.onAuthStateChanged(user => {
        setLoading(false);
        setCurrentUser(user);
      }),
    [auth]
  );

  useEffect(
    () =>
      auth &&
      auth.onIdTokenChanged(user => {
        // User is signed in or token was refreshed.
        setCurrentUser(user);
      }),
    [auth]
  );

  return {
    isLoading,
    isAuthenticated: !isEmpty(currentUser),
    user: currentUser
  };
};
