import React, { useMemo, useState, useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import { map, sample } from "lodash";
import moment from "moment";
import { QUESTIONS, prepareQuestions } from "@the-study-pro/reports";
import useQuery from "../hooks/useQuery";
import { AnswersMap, QuestionType, StudentInformationType } from "../types";

import usePersistentState from "../hooks/usePersistentState";
import If from "../components/helpers/If";
import StudentInformationForm from "../components/StudentInformationForm";
import Assessment from "../components/Assessment";
import isDevelopment from "../utils/isDevelopment";
import useSubmitAssessmentResult from "../hooks/useSubmitAssessmentResult";
import useGenerateAssessmentReport from "../hooks/useGenerateAssessmentReport";
import scoreSubmittedAnswers from "../utils/scoreSubmittedAnswers";
import prepareAssessmentReportParams from "../utils/prepareAssessmentReportParams";
import AssessmentComplete from "../components/AssessmentComplete";

type AssessmentState = "registration" | "assessment" | "complete";
const INITIAL_ASSESSMENT_STATE: AssessmentState = "registration";

const DEFAULT_STUDENT_INFORMATION: StudentInformationType = {
  name: "",
  email: "",
  grade: "",
  passcode: "",
};

const getInitialAnswers = (questions: QuestionType[]): AnswersMap => {
  if (isDevelopment()) {
    return map(questions, (_question, index) => ({
      index,
      score: sample([1, 2, 3, 4, 5]),
    }));
  }
  return {};
};

const getInitialStudentInformation = (passcode: string) => ({
  ...DEFAULT_STUDENT_INFORMATION,
  passcode,
});

function usePersistentMountCount(mountName: string) {
  const [mountCount, setMountCount] = usePersistentState(
    0,
    `${mountName}.mount-count`
  );
  useEffect(() => setMountCount((count) => count + 1), [setMountCount]);

  return mountCount;
}

function wasAssessmentCompletedInOneSession(mountCount: number) {
  return mountCount === 1;
}

export default function AssessmentPage() {
  const { passcode } = useQuery();

  // Track the number of mounts and only record the duration when this is 1
  const mountCount = usePersistentMountCount("assessment");
  const startTimeInMillis = useMemo(() => Date.now(), []);

  const [questions] = usePersistentState(
    prepareQuestions(QUESTIONS),
    "assessment.questions"
  );

  const [state, setState] = useState(INITIAL_ASSESSMENT_STATE);

  const [studentInformation, setStudentInformation] = usePersistentState(
    getInitialStudentInformation(passcode),
    "assessment.student-information"
  );

  const [answers, setAnswers] = useState(getInitialAnswers(questions));

  const onRegisterStudent = (info: StudentInformationType) => {
    setStudentInformation(info);
    setState("assessment");
  };

  const submitAssessmentResult = useSubmitAssessmentResult();
  const generateAssessmentReport = useGenerateAssessmentReport();

  const onSubmitAnswers = (submittedAnswers: AnswersMap) => {
    setAnswers(submittedAnswers);
    setState("complete");
    window.scrollTo(0, 0);

    const date = moment().format("MMMM DD, YYYY");
    const scores = scoreSubmittedAnswers(questions, submittedAnswers);
    const durationInMillis = wasAssessmentCompletedInOneSession(mountCount)
      ? Date.now() - startTimeInMillis
      : null;

    console.log({
      questions,
      submittedAnswers,
      studentInformation,
      scores,
      durationInMillis,
    });

    submitAssessmentResult({
      student: studentInformation,
      date,
      durationInMillis,
      scores,
      questions,
      answers: submittedAnswers,
    });

    const reportParams = prepareAssessmentReportParams(
      studentInformation,
      date,
      scores
    );

    generateAssessmentReport(reportParams);
  };

  return (
    <div>
      <If test={state === "registration"}>
        <div className="min-vh-100 d-flex flex-column align-items-center justify-content-center">
          <div className="text-center">
            <a href="https://thestudypro.com">
              <img
                src="/logo.png"
                alt="The StudyPro"
                className="mb-5 img-fluid"
                style={{ width: 300 }}
              />
            </a>
            <h1 className="h3">
              Executive Function <small>&amp;</small> Study Skills
            </h1>
            <h2 className="h4 text-muted">Self-Assessment</h2>
          </div>
          <div className="m-3 rounded bg-white shadow p-3">
            <StudentInformationForm
              initialStudentInformation={studentInformation}
              onSubmit={onRegisterStudent}
            />
          </div>
        </div>
      </If>

      <If test={state === "assessment"}>
        <Container className="py-5">
          <Row className="mt-5 mb-3">
            <Col className="text-center">
              <h1 className="h3">
                Executive Function <small>&amp;</small> Study Skills
              </h1>
              <h2 className="h4 text-muted">Self-Assessment</h2>
            </Col>
          </Row>
          <Row>
            <Col>
              <Assessment
                answers={answers}
                questionsPerPage={10}
                questions={questions}
                onSubmit={onSubmitAnswers}
              />
            </Col>
          </Row>
        </Container>
      </If>

      <If test={state === "complete"}>
        <div className="min-vh-100 d-flex flex-column align-items-center justify-content-center">
          <div className="m-3 rounded bg-white shadow p-3">
            <AssessmentComplete />
          </div>
        </div>
      </If>
    </div>
  );
}
