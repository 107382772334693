import React from "react";
import { Button } from "reactstrap";
import Icon from "@mdi/react";
import { mdiStar, mdiStarOutline } from "@mdi/js";

const getRatingIcon = (index, value) =>
  index <= value ? mdiStar : mdiStarOutline;

const getRatingColor = (index, value) =>
  index <= value ? "text-warning" : "text-muted";

const RatingButton = ({ index, value, onClick, size }) => (
  <Button
    type="button"
    size="sm"
    className="py-2 mr-2"
    onClick={onClick}
    data-index={index}
    title={`Give a ${index} star rating`}
  >
    <Icon path={getRatingIcon(index, value)} size={size} />
  </Button>
);

const RatingDisplay = ({ index, value, size }) => (
  <div className={["d-inline-block", getRatingColor(index, value)].join(" ")}>
    <Icon path={getRatingIcon(index, value)} size={size} />
  </div>
);

export default function Rating({ value, onChange, size = 1 }) {
  const onClick = e => {
    if (onChange) {
      onChange(e.target.dataset.index);
    }
  };
  return onChange ? (
    <div className="mb-3">
      <RatingButton index={1} value={value} size={size} onClick={onClick} />
      <RatingButton index={2} value={value} size={size} onClick={onClick} />
      <RatingButton index={3} value={value} size={size} onClick={onClick} />
      <RatingButton index={4} value={value} size={size} onClick={onClick} />
      <RatingButton index={5} value={value} size={size} onClick={onClick} />
    </div>
  ) : (
    <div
      className="d-inline-block"
      aria-label={`Rated ${value} out of 5 stars`}
    >
      <RatingDisplay index={1} value={value} size={size} />
      <RatingDisplay index={2} value={value} size={size} />
      <RatingDisplay index={3} value={value} size={size} />
      <RatingDisplay index={4} value={value} size={size} />
      <RatingDisplay index={5} value={value} size={size} />
    </div>
  );
}
