import React, { FormEvent } from "react";
import {
  Button,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import useFormInputState from "../hooks/useFormInputState";

interface Props {
  courseName: string;
  onRename: (newName: string) => void;
  onCancel: () => void;
}

const CourseRenameModal = ({ courseName, onRename, onCancel }: Props) => {
  const [newName, onChangeCourseName] = useFormInputState(courseName);
  const onPerformRename = () => onRename(newName);

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    e.stopPropagation();
    onRename(newName);
  };

  return (
    <form onSubmit={onSubmit}>
      <ModalHeader toggle={onCancel}>Change Course Name</ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label for="course-name">Course name</Label>
          <Input type="text" value={newName} onChange={onChangeCourseName} />
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={onPerformRename}>
          Rename
        </Button>{" "}
        <Button color="secondary" onClick={onCancel}>
          Cancel
        </Button>
      </ModalFooter>
    </form>
  );
};

export default CourseRenameModal;
