import * as firebase from "firebase/app";
import useAuth from "./useAuth";
import useErrorProvider from "./useErrorProvider";

const MERGE = { merge: true };

export default function useUpdateAccountProfile() {
  const { user } = useAuth();
  const { reportError } = useErrorProvider();
  const db = firebase.firestore();
  return profile =>
    db
      .collection("profile")
      .doc(user.uid)
      .set(
        {
          ...profile,
          updated: firebase.firestore.Timestamp.now(),
          uid: user.uid
        },
        MERGE
      )
      .catch(error => {
        reportError(error.message);
      });
}
