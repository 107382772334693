import { isEmpty } from "lodash";

const ROOT_URL = process.env.REACT_APP_PUBLIC_URL || "";

console.log(`Using root: "${ROOT_URL}"`);

export default {
  landing: `${ROOT_URL}/`,

  dashboard: `${ROOT_URL}/dashboard`,

  register: `${ROOT_URL}/assessment`,
  assessment: `${ROOT_URL}/assessment`,

  styleguide: `${ROOT_URL}/styleguide`,
  report: `${ROOT_URL}/report`,

  account: {
    signup: `${ROOT_URL}/account/sign-up`,
    signin: `${ROOT_URL}/account/sign-in`,
    signinWithRedirect: (to = ``) =>
      `${ROOT_URL}/account/sign-in?redirect=${encodeURIComponent(to)}`,
    signout: `${ROOT_URL}/account/sign-out`,
    recover: `${ROOT_URL}/account/recovery`,
    reset: `${ROOT_URL}/account/reset-password`,
    profile: `${ROOT_URL}/account/profile`,
    loading: `${ROOT_URL}/account/loading`,
    loadingWithRedirect: (to = ``) => {
      return isEmpty(to)
        ? `${ROOT_URL}/account/loading`
        : `${ROOT_URL}/account/loading?redirect=${to}`;
    },
    action: `${ROOT_URL}/account/action`,
  },

  support: {
    dashboard: `${ROOT_URL}/support/dashboard`,
    analysis: `${ROOT_URL}/support/analysis`,
    accounts: `${ROOT_URL}/support/accounts`,
    courses: `${ROOT_URL}/support/courses`,
    email: `mailto:report@thestudypro.com`,
  },

  redirect: (destination = `:destination`) =>
    `${ROOT_URL}/redirect/${destination}`,
};
