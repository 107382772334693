import React from "react";
import { InputGroup, InputGroupAddon, Input, Button } from "reactstrap";
import useCopy from "@react-hook/copy";
import { CourseType } from "../types";

interface Props {
  course: CourseType;
}

export default function AssessmentSharingDetails({ course }: Props) {
  const url = `${window.location.protocol}//${window.location.host}/assessment?passcode=${course.passcode}`;
  const { copy, copied } = useCopy(url);

  return (
    <div className="d-flex flex-column align-items-center justify-content-center m-5">
      <p>
        Share this exact URL with the students enrolled in the {course.name}{" "}
        course:
      </p>
      <InputGroup>
        <Input readOnly value={url} />
        <InputGroupAddon addonType="append">
          <Button onClick={copy} disabled={copied}>
            {copied ? "Copied" : "Copy to Clipboard"}
          </Button>
        </InputGroupAddon>
      </InputGroup>
    </div>
  );
}
