import React from "react";
import { Container, Row, Col } from "reactstrap";

import { QUESTIONS, prepareQuestions } from "@the-study-pro/reports";

import Rating from "../../components/Rating";
import DateTime from "../../components/DateTime";
import BusyButton from "../../components/BusyButton";
import Question from "../../components/Question";
import Questions from "../../components/Questions";
import Assessment from "../../components/Assessment";
import StudentInformationForm from "../../components/StudentInformationForm";
import AddCourseForm from "../../components/AddCourseForm";
import AssessmentComplete from "../../components/AssessmentComplete";
import AssessmentSharingDetails from "../../components/AssessmentSharingDetails";
import ManualAssessmentEntryForm from "../../components/ManualAssessmentEntryForm";
import BusyMessage from "../../components/BusyMessage";
import DeleteConfirmation from "../../components/DeleteConfirmation";
import AssessmentReview from "../../components/AssessmentReview";

const components = [
  {
    Component: AssessmentReview,
    props: {},
  },

  {
    Component: AssessmentReview,
    props: {
      questions: [
        {
          text:
            "I want to know the BEST or the RIGHT way to do something.  I don’t like if there are multiple ways to do something.",
          index: 0,
          category: "EF Skill",
          group: "Shifting",
        },
        {
          text:
            "I have trouble knowing due dates and work on a project or essay last minute and all at once.",
          category: "EF Skill",
          group: "Planning and Organization",
          index: 1,
        },
        {
          text:
            "When I am stuck or frustrated, I have a hard time doing anything.",
          index: 2,
          category: "EF Skill",
          group: "Emotional Control",
        },
      ],
      answers: [
        {
          score: 1,
          index: 0,
        },
        {
          score: 2,
          index: 1,
        },
        {
          score: 2,
          index: 2,
        },
      ],
    },
  },

  {
    Component: DeleteConfirmation,
    props: {
      onDelete: () => alert("Record deleted"),
    },
  },

  {
    Component: BusyMessage,
    props: {
      children: "Busy working...",
    },
  },

  {
    Component: ManualAssessmentEntryForm,
    props: {},
  },

  {
    Component: AssessmentSharingDetails,
    props: { course: { name: "Test Course", passcode: "testing-passcode" } },
  },

  {
    Component: AssessmentComplete,
    props: {},
  },

  {
    Component: AddCourseForm,
    props: {},
  },

  {
    Component: StudentInformationForm,
    props: {},
  },
  {
    Component: Assessment,
    props: {
      questionsPerPage: 10,
      questions: prepareQuestions(QUESTIONS),
    },
  },

  {
    Component: Assessment,
    props: {
      questionsPerPage: 2,
      questions: [
        {
          index: 0,
          text: "I have a hard time waiting to be called on.",
        },
        {
          index: 1,
          text:
            "I will skip assigned readings if I don't have anything to turn in for that reading.",
        },
        {
          index: 2,
          text: "I underestimate or overestimate how long things will take.",
        },
        {
          index: 3,
          text: "I don't know what to study.",
        },
      ],
    },
  },

  {
    Component: Questions,
    props: {
      questions: [
        {
          index: 0,
          text: "I have a hard time waiting to be called on.",
        },
        {
          index: 1,
          text:
            "I will skip assigned readings if I don't have anything to turn in for that reading.",
        },
      ],
      values: {
        0: { index: 0, score: 3 },
        1: { index: 1, score: 5 },
      },
    },
  },

  {
    Component: Question,
    props: {
      index: 0,
      text: "I have a hard time waiting to be called on.",
    },
  },
  {
    Component: Question,
    props: {
      index: 0,
      text: "I have a hard time waiting to be called on.",
      value: 3,
    },
  },

  {
    Component: BusyButton,
    props: { color: "success", children: "Save", isBusy: false },
  },
  {
    Component: BusyButton,
    props: { color: "success", busyLabel: "Loading", isBusy: true },
  },
  {
    Component: BusyButton,
    props: { color: "success", isBusy: true },
  },

  {
    Component: DateTime,
    props: {
      value: Date.now(),
      onChange: console.log,
    },
  },
  {
    Component: DateTime,
    props: {
      value: null,
      onChange: console.log,
    },
  },
  {
    Component: DateTime,
    props: {
      invalid: true,
      value: null,
      onChange: console.log,
    },
  },

  {
    Component: Rating,
    props: {
      value: 3,
      onChange(value) {
        alert(value);
      },
    },
  },
  { Component: Rating, props: { value: 3 } },
];

export default () => (
  <Container className="py-5">
    <Row className="mt-5">
      <Col>
        <h1 className="text-center">Styleguide</h1>
      </Col>
    </Row>
    <Row>
      <Col>
        {components.map(({ Component, props = {} }, index) => (
          <div
            className="mb-5 border border-dark rounded p-3 bg-white"
            key={index}
          >
            <code className="d-block mb-3">{Component.name}</code>
            <Component {...props} />
          </div>
        ))}
      </Col>
    </Row>
  </Container>
);
