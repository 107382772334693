import { get } from "lodash";
import { AssessmentResultRecordType, CoursesMap } from "../types";
import getAssessmentCourse from "./getAssessmentCourse";

export default function getAssessmentCourseName(
  courses: CoursesMap,
  assessment: AssessmentResultRecordType
) {
  return get(getAssessmentCourse(courses, assessment), "name");
}
