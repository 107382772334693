function downloadFile(filename: string, blob: Blob) {
  const link = document.createElement("a");
  link.setAttribute("href", URL.createObjectURL(blob));
  link.setAttribute("download", filename);
  link.style.display = "none";
  document.body.appendChild(link);
  link.click();
}

export default function useDownload() {
  return (filename: string, blob: Blob) => {
    downloadFile(filename, blob);
  };
}
