import * as firebase from "firebase/app";
import { SERVICE_ROOT_URL } from "../configuration";
import useErrorProvider from "./useErrorProvider";
import { useNotification } from "../components/Notification";

export default function useNotifyCustomer() {
  const { notify } = useNotification();
  const { reportError } = useErrorProvider();

  return async uidToPromote => {
    try {
      const idToken = await firebase
        .auth()
        .currentUser.getIdToken(/* forceRefresh */ true);

      const result = await fetch(`${SERVICE_ROOT_URL}/promote`, {
        method: "PUT",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "Authorization-Token": idToken
        },
        body: JSON.stringify({
          uidToPromote,
          environment: process.env.NODE_ENV
        })
      });

      const json = await result.json();

      const { message = "Account promoted to support" } = json;

      notify(message, "success");

      return json;
    } catch (e) {
      reportError(e);
    }
  };
}
