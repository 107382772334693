import React, { ChangeEvent } from "react";
import { FormGroup, Input } from "reactstrap";

import useFormInputState from "../../hooks/useFormInputState";
import { SERVICE_ROOT_URL } from "../../configuration";

interface PageSelectProps {
  value: string;
  onChange: (pageKey: string) => void;
}

const PageSelect = ({ value, onChange }: PageSelectProps) => {
  const onChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };
  return (
    <FormGroup>
      <Input type="select" value={value} onChange={onChangeInput}>
        <optgroup label="Signature">
          <option value="emotional-control">Emotional Control</option>
          <option value="inhibition">Inhibition</option>
          <option value="initiation">Initiation</option>
          <option value="materials-organization">Materials Organization</option>
          <option value="planning-and-organization">
            Planning &amp; Organization
          </option>
          <option value="self-monitoring">Self-monitoring</option>
          <option value="shifting">Shifting</option>
          <option value="working-memory">Working Memory</option>
        </optgroup>

        <optgroup label="Strategy">
          <option value="emotional-control">Emotional Control</option>
          <option value="inhibition">Inhibition</option>
          <option value="initiation">Initiation</option>
          <option value="materials-organization">Materials Organization</option>
          <option value="planning-and-organization">
            Planning &amp; Organization
          </option>
          <option value="self-monitoring">Self-monitoring</option>
          <option value="shifting">Shifting</option>
          <option value="working-memory">Working Memory</option>
        </optgroup>
      </Input>
    </FormGroup>
  );
};

export default function TheReport() {
  const [name, onChangeName] = useFormInputState("Joe Student");
  const [grade, onChangeGrade] = useFormInputState("Grade 9");
  const [date, onChangeDate] = useFormInputState("July 20, 2020");

  const [strength1, onChangeStrength1] = useFormInputState("working-memory");
  const [strength2, onChangeStrength2] = useFormInputState("shifting");
  const [strength3, onChangeStrength3] = useFormInputState("emotional-control");
  const [strategy1, onChangeStrategy1] = useFormInputState("self-monitoring");
  const [strategy2, onChangeStrategy2] = useFormInputState("inhibition");
  const [strategy3, onChangeStrategy3] = useFormInputState("initiation");
  const [strategy4, onChangeStrategy4] = useFormInputState(
    "materials-organization"
  );
  const [strategy5, onChangeStrategy5] = useFormInputState(
    "planning-and-organization"
  );

  const params = {
    name,
    grade,
    date,
    strength1,
    strength2,
    strength3,
    strategy1,
    strategy2,
    strategy3,
    strategy4,
    strategy5,
  };

  return (
    <div className="vh-100 d-flex shadow">
      <iframe
        title="Report sandbox"
        src={`${SERVICE_ROOT_URL}/report?${new URLSearchParams(
          params
        ).toString()}`}
        style={{ minHeight: "100%", width: "50%", border: "none" }}
      />
      <div className="w-50 h-100">
        <div className="p-3">
          <legend>Metadata</legend>
          <FormGroup>
            <Input type="text" value={name} onChange={onChangeName} />
          </FormGroup>
          <FormGroup>
            <Input type="text" value={grade} onChange={onChangeGrade} />
          </FormGroup>
          <FormGroup>
            <Input type="text" value={date} onChange={onChangeDate} />
          </FormGroup>

          <legend>Strengths</legend>
          <PageSelect value={strength1} onChange={onChangeStrength1} />
          <PageSelect value={strength2} onChange={onChangeStrength2} />
          <PageSelect value={strength3} onChange={onChangeStrength3} />

          <legend>Strategies</legend>
          <PageSelect value={strategy1} onChange={onChangeStrategy1} />
          <PageSelect value={strategy2} onChange={onChangeStrategy2} />
          <PageSelect value={strategy3} onChange={onChangeStrategy3} />
          <PageSelect value={strategy4} onChange={onChangeStrategy4} />
          <PageSelect value={strategy5} onChange={onChangeStrategy5} />
        </div>
      </div>
    </div>
  );
}
