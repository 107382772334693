import * as firebase from "firebase/app";
import useErrorProvider from "./useErrorProvider";

export default function useDeleteCourse() {
  const { reportError } = useErrorProvider();
  const db = firebase.firestore();
  return (courseId) =>
    db
      .doc(`course/${courseId}`)
      .delete()
      .catch((e) => reportError(e, "useDeleteCourse"));
}
