import React, { useEffect } from "react";
import { Route, useLocation } from "react-router";
import { Container, Row, Col, Spinner } from "reactstrap";

import routes from "../../routes";
import useAuth from "../../hooks/useAuth";
import useRouter from "../../hooks/useRouter";

const Loading = () => (
  <div className="min-vh-100 w-100 d-flex justify-content-center align-items-center">
    <Container className="mt-5">
      <Row>
        <Col md={{ size: 6, offset: 3 }}>
          <div className="my-5 bg-white rounded p-3 shadow-sm">
            <div className="d-flex align-items-center justify-content-between">
              <div>Loading your account...</div>
              <Spinner color="primary" size="3" />
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  </div>
);

// A wrapper for <Route> that redirects to the sign
// in screen if the user is not yet authenticated.
export default function ProtectedRoute({ children, ...rest }) {
  const { isAuthenticated, isLoading } = useAuth();
  const { routeTo } = useRouter();
  const loc = useLocation();
  const { pathname } = loc;

  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      routeTo(routes.account.signinWithRedirect(pathname));
    }
  }, [isAuthenticated, isLoading, routeTo, pathname]);

  return (
    <Route
      {...rest}
      render={() => (isAuthenticated && children) || (isLoading && <Loading />)}
    />
  );
}
