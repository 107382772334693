import { SERVICE_ROOT_URL } from "../configuration";
import useErrorProvider from "./useErrorProvider";
import { AssessmentReportParamsType } from "../types";

export default function useGenerateAssessmentReport() {
  const { reportError } = useErrorProvider();
  return async (
    reportParams: AssessmentReportParamsType
  ): Promise<Blob | undefined> => {
    try {
      const result = await fetch(`${SERVICE_ROOT_URL}/report`, {
        method: "PUT",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...reportParams,
          environment: process.env.NODE_ENV,
        }),
      });

      const report = await result.blob();

      return report;
    } catch (e) {
      reportError(e, "useGenerateAssessmentReport");
    }
  };
}
