import * as firebase from "firebase/app";
import useAuth from "./useAuth";
import useErrorProvider from "./useErrorProvider";
import { useState, useEffect } from "react";
import getDataFromDocuments from "../utils/getDataFromDocuments";
import useSupportProfile from "./useSupportProfile";
import { AssessmentResultRecordType } from "../types";

type HookReturnValue = [AssessmentResultRecordType[], boolean];

export default function useAllAssessmentResults(): HookReturnValue {
  const [isLoading, setLoading] = useState(false);
  const [assessments, setAssessments] = useState<AssessmentResultRecordType[]>(
    []
  );
  const { user } = useAuth();
  const supportProfile = useSupportProfile();
  const { reportError } = useErrorProvider();

  useEffect(() => {
    setLoading(true);
    const db = firebase.firestore();
    return db
      .collection("assessment")
      .orderBy("created", "desc")
      .onSnapshot({
        next(result) {
          setLoading(false);
          setAssessments(getDataFromDocuments(result.docs));
        },
        error: (e) => reportError(e, "useAllAssessmentResults"),
      });
  }, [reportError, user, supportProfile]);

  return [assessments, isLoading];
}
