import { get } from "lodash";
import moment from "moment";
import {
  StudentInformationType,
  ScoresMap,
  AssessmentReportParamsType,
} from "../types";
import { mapGroupToPageKey } from "@the-study-pro/reports";

export default function prepareAssessmentReportParams(
  studentInformation: StudentInformationType,
  date: string = moment().format("MMMM DD, YYYY"),
  scores: ScoresMap
): AssessmentReportParamsType {
  const { name, email, grade } = studentInformation;
  const [
    { group: strength1 },
    { group: strength2 },
    { group: strength3 },
    { group: strategy1 },
    { group: strategy2 },
    { group: strategy3 },
    { group: strategy4 },
    { group: strategy5 },
  ] = get(scores, "EF Skill");
  return {
    name,
    email,
    grade,
    date,
    scores,
    strength1: mapGroupToPageKey(strength1),
    strength2: mapGroupToPageKey(strength2),
    strength3: mapGroupToPageKey(strength3),
    strategy1: mapGroupToPageKey(strategy1),
    strategy2: mapGroupToPageKey(strategy2),
    strategy3: mapGroupToPageKey(strategy3),
    strategy4: mapGroupToPageKey(strategy4),
    strategy5: mapGroupToPageKey(strategy5),
  };
}
