import * as firebase from "firebase/app";
import useErrorProvider from "./useErrorProvider";

export default function useDeleteAssessment() {
  const { reportError } = useErrorProvider();
  const db = firebase.firestore();
  return (assessmentId) =>
    db
      .doc(`assessment/${assessmentId}`)
      .delete()
      .catch((e) => reportError(e, "useDeleteAssessment"));
}
