import React, { FormEvent, useState, ChangeEvent } from "react";
import { Form, FormGroup, Label, Input, Button, Col } from "reactstrap";
import { get, isEmpty, map, mapValues, toNumber } from "lodash";
import { QUESTIONS_BY_CATEGORY_AND_GROUP } from "@the-study-pro/reports";

import useFormInputState from "../hooks/useFormInputState";
import { StudentInformationType, ScoresMap } from "../types";
import isDevelopment from "../utils/isDevelopment";

type ManualScoresMap = {
  [category: string]: {
    [group: string]: number;
  };
};

interface GroupScoreFieldProps {
  category: string;
  group: string;
  score: number;
  onChange: (category: string, group: string, score: number) => void;
}

function GroupScoreField({
  category,
  group,
  score,
  onChange,
}: GroupScoreFieldProps) {
  const [isFocused, setFocus] = useState(false);
  const onFocus = () => setFocus(true);
  const onBlur = () => setFocus(false);
  const labelClassName = isFocused ? "text-primary font-weight-bold" : "";

  const onChangeScore = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(category, group, toNumber(e.target.value));
  };

  return (
    <FormGroup row className="m-0 py-1 d-flex align-items-center bg-zebra">
      <Label for={group} sm={8} className={labelClassName}>
        {group}
      </Label>
      <Col sm={4}>
        <Input
          bsSize="sm"
          name={group}
          type="number"
          min={5}
          max={25}
          value={score}
          onFocus={onFocus}
          onBlur={onBlur}
          onChange={onChangeScore}
        />
      </Col>
    </FormGroup>
  );
}

function getDefaultScores(): ManualScoresMap {
  return mapValues(QUESTIONS_BY_CATEGORY_AND_GROUP, (groups) =>
    mapValues(groups, (_questions) => 5)
  );
}

function toScoresMap(scores: ManualScoresMap): ScoresMap {
  return mapValues(scores, (groups) =>
    map(groups, (score, group) => ({ group, score }))
  );
}

interface Props {
  onSubmit: (
    studentInformation: StudentInformationType,
    scores: ScoresMap
  ) => void;
}

export default function ManualAssessmentEntryForm(props: Props) {
  const [name, onChangeName] = useFormInputState("");
  const [grade, onChangeGrade] = useFormInputState("");
  const [passcode, onChangePasscode] = useFormInputState("");
  const [manualScores, setManualScores] = useState(getDefaultScores());

  const onChangeScore = (category: string, group: string, score: number) => {
    setManualScores((scores) => ({
      ...scores,
      [category]: {
        ...scores[category],
        [group]: score,
      },
    }));
  };

  const canSubmit =
    isDevelopment() ||
    (!isEmpty(name) && !isEmpty(grade) && !isEmpty(passcode));

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();

    const studentInformation: StudentInformationType = {
      name,
      grade,
      passcode,
      email: "",
    };

    const scores = toScoresMap(manualScores);

    props.onSubmit(studentInformation, scores);
  };

  return (
    <Form onSubmit={onSubmit}>
      <legend>Student Information</legend>
      <FormGroup row>
        <Label for="name" sm={4}>
          Student Name
        </Label>
        <Col sm={8}>
          <Input
            name="name"
            type="text"
            placeholder="Please enter the student's full name"
            value={name}
            onChange={onChangeName}
          />
        </Col>
      </FormGroup>

      <FormGroup row>
        <Label for="grade" sm={4}>
          Student Grade
        </Label>
        <Col sm={8}>
          <Input
            name="grade"
            type="select"
            value={grade}
            onChange={onChangeGrade}
          >
            <option value=""></option>
            <option>4th Grade</option>
            <option>5th Grade</option>
            <option>6th Grade</option>
            <option>7th Grade</option>
            <option>8th Grade</option>
            <option>9th Grade</option>
            <option>10th Grade</option>
            <option>11th Grade</option>
            <option>12th Grade</option>
            <option>College</option>
            <option>Other</option>
          </Input>
        </Col>
      </FormGroup>

      <FormGroup row>
        <Label for="code" sm={4}>
          Assessment Code
        </Label>
        <Col sm={8}>
          <Input
            name="code"
            type="text"
            value={passcode}
            onChange={onChangePasscode}
          />
        </Col>
      </FormGroup>

      <legend className="mt-5">Assessment Scores</legend>
      {map(QUESTIONS_BY_CATEGORY_AND_GROUP, (groups, category) => (
        <div key={category}>
          <strong className="d-block border-bottom mt-4 mb-2 text-uppercase">
            {category}
          </strong>
          {map(groups, (_questions, group) => (
            <GroupScoreField
              key={group}
              category={category}
              group={group}
              score={get(manualScores, [category, group])}
              onChange={onChangeScore}
            />
          ))}
        </div>
      ))}

      <div className="text-center mt-5">
        <Button
          type="submit"
          color="success"
          size="lg"
          className="shadow"
          disabled={!canSubmit}
        >
          Record Assessment Result
        </Button>
      </div>
    </Form>
  );
}
