import React, { useState, Fragment } from "react";
import {
  Container,
  Navbar,
  NavbarBrand,
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import Icon from "@mdi/react";
import {
  mdiAccountCircle,
  mdiAccountMultiple,
  mdiLogoutVariant,
  mdiPaletteSwatch,
  mdiMenu,
  mdiMenuDown,
} from "@mdi/js";

import routes from "../routes";
import isSupportAccount from "../utils/isSupportAccount";
import useAuth from "../hooks/useAuth";
import useSupportProfile from "../hooks/useSupportProfile";
import IfSupport from "./helpers/IfSupport";
import IfDevelopment from "./helpers/IfDevelopment";

const CustomerNav = ({ user }) => {
  const supportProfile = useSupportProfile();

  const [isNavOpen, setNavOpen] = useState();
  const onToggleNav = () => setNavOpen(!isNavOpen);

  const homeHref = isSupportAccount(supportProfile)
    ? routes.support.dashboard
    : routes.dashboard;

  return (
    <Navbar expand color="white">
      <Container>
        <NavbarBrand href={homeHref} className="ml-3 pl-1 mr-auto">
          The StudyPro
        </NavbarBrand>

        <Dropdown isOpen={isNavOpen} toggle={onToggleNav}>
          <DropdownToggle color="dark" size="sm" outline>
            <span className="d-none d-md-inline">{user.email}</span>
            <Icon className="d-inline d-md-none" path={mdiMenu} size={1} />
            <Icon
              className="d-none d-md-inline ml-md-2"
              path={mdiMenuDown}
              size={0.7}
            />
          </DropdownToggle>

          <DropdownMenu right>
            <DropdownItem tag="a" href={routes.account.profile}>
              <Icon path={mdiAccountCircle} size={1} /> My Profile
            </DropdownItem>
            <IfSupport>
              <DropdownItem divider />
              <DropdownItem tag="a" href={routes.support.accounts}>
                <Icon path={mdiAccountMultiple} size={1} /> Manage Accounts
              </DropdownItem>
            </IfSupport>
            <IfDevelopment>
              <DropdownItem divider />
              <DropdownItem tag="a" href={routes.styleguide}>
                <Icon path={mdiPaletteSwatch} size={1} /> Styleguide
              </DropdownItem>
            </IfDevelopment>
            <DropdownItem divider />
            <DropdownItem tag="a" href={routes.account.signout}>
              <Icon path={mdiLogoutVariant} size={1} /> Sign Out
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </Container>
    </Navbar>
  );
};

const GuestNav = () => (
  <Navbar expand color="white">
    <Container>
      <NavbarBrand href={routes.landing} className="ml-3 pl-1 mr-auto">
        The StudyPro
      </NavbarBrand>

      <Button color="link" href={routes.account.signin}>
        Sign In
      </Button>
      <Button color="primary" outline href={routes.account.signup}>
        Sign Up
      </Button>
    </Container>
  </Navbar>
);

export default () => {
  const { isAuthenticated, user } = useAuth();

  return (
    <Fragment>
      <div className="fixed-top shadow-sm">
        {isAuthenticated && user ? <CustomerNav user={user} /> : <GuestNav />}
      </div>
      <div style={{ height: 58 }} />
    </Fragment>
  );
};
