import * as firebase from "firebase/app";
import useErrorProvider from "./useErrorProvider";

import { AssessmentResultRecordType } from "../types";

export default function useSubmitAssessmentResult() {
  const { reportError } = useErrorProvider();
  const db = firebase.firestore();

  return (record: AssessmentResultRecordType) =>
    db
      .collection("assessment")
      .add({
        ...record,
        created: firebase.firestore.Timestamp.now(),
      })
      .catch((e) => reportError(e, "useSubmitAssessmentResult"));
}
