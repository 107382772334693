import React, { useState, Fragment } from "react";
import { map, isEmpty } from "lodash";
import {
  Container,
  Row,
  Col,
  Button,
  Table,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import moment from "moment";

import Layout from "../Layout";
import If from "../../components/helpers/If";
import useAllAssessmentResults from "../../hooks/useAllAssessmentResults";
import getAssessmentDate from "../../utils/getAssessmentDate";
import getAssessmentCourseName from "../../utils/getAssessmentCourseName";
import getStudentName from "../../utils/getStudentName";
import getStudentGrade from "../../utils/getStudentGrade";
import getStudentEmail from "../../utils/getStudentEmail";
import {
  AssessmentResultRecordType,
  CoursesMap,
  StudentInformationType,
  ScoresMap,
} from "../../types";
import { useNotification } from "../../components/Notification";
import useGenerateAssessmentReport from "../../hooks/useGenerateAssessmentReport";
import useSubmitAssessmentResult from "../../hooks/useSubmitAssessmentResult";
import prepareAssessmentReportParams from "../../utils/prepareAssessmentReportParams";
import useAllCourses from "../../hooks/useAllCourses";
import useDownload from "../../hooks/useDownload";
import useDeleteAssessment from "../../hooks/useDeleteAssessment";
import AssessmentResultFilter from "../../components/AssessmentResultFilter";
import filterAssessmentResults from "../../utils/filterAssessmentResults";
import SupportNavigation from "../../components/SupportNavigation";
import getAssessmentCoursePasscode from "../../utils/getAssessmentCoursePasscode";
import ManualAssessmentEntryForm from "../../components/ManualAssessmentEntryForm";
import toggle from "../../utils/toggle";
import BusyMessage from "../../components/BusyMessage";
import { mdiClose, mdiDownload, mdiFileCheckOutline, mdiPlus } from "@mdi/js";
import Icon from "@mdi/react";
import DeleteConfirmation from "../../components/DeleteConfirmation";
import AssessmentReview from "../../components/AssessmentReview";

interface AssessmentRowProps {
  assessment: AssessmentResultRecordType;
  courses: CoursesMap;
}

function AssessmentRow({ assessment, courses }: AssessmentRowProps) {
  const { notify } = useNotification();
  const generateAssessmentReport = useGenerateAssessmentReport();
  const download = useDownload();
  const deleteAssessment = useDeleteAssessment();

  const [isGeneratingReport, setGeneratingReport] = useState(false);

  const [isDeletingAssessment, setDeletingAssessment] = useState(false);
  const onDeleteAssessment = () => setDeletingAssessment(true);
  const onToggleDeletingAssessment = () => setDeletingAssessment(toggle);
  const onCancelDeletion = () => setDeletingAssessment(false);

  const onConfirmDelete = () => {
    setDeletingAssessment(false);
    deleteAssessment(assessment.id);
  };

  const onGenerateReport = async () => {
    const studentName = getStudentName(assessment);

    const reportParams = prepareAssessmentReportParams(
      assessment.student,
      assessment.date,
      assessment.scores
    );

    try {
      setGeneratingReport(true);
      const report = await generateAssessmentReport(reportParams);

      if (report) {
        const filename = `${studentName} Self-Assessment.pdf`;
        download(filename, report);

        const message = `The assessment report for ${studentName} has been re-generated. Your download should start momentarily and the report will also be emailed.`;

        notify(message, "success");
      } else {
        const message = `Failed to generate the assessment report for ${studentName}`;
        notify(message, "danger");
      }
    } catch (e) {
      const message = `Failed to generate the assessment report for ${studentName}`;
      notify(message, "danger");
    } finally {
      setGeneratingReport(false);
    }
  };

  const [isViewing, setViewing] = useState(false);

  const onViewAssessment = () => setViewing(true);
  const onToggleViewingAssessment = () => setViewing(toggle);
  const onDismissAssessment = () => setViewing(false);

  return (
    <Fragment>
      <tr>
        <td>
          <strong>{getStudentName(assessment)}</strong>
          <br />
          <span className="text-muted">{getStudentGrade(assessment)}</span>
          <br />
          <small className="text-muted">{getStudentEmail(assessment)}</small>
        </td>
        <td>
          <small>{getAssessmentDate(assessment)}</small>
        </td>
        <td>
          <small>{getAssessmentCourseName(courses, assessment)}</small>
          <br />
          <small className="text-muted text-monospace">
            {getAssessmentCoursePasscode(assessment)}
          </small>
        </td>
        <td className="text-right">
          <Button
            size="sm"
            outline
            onClick={onGenerateReport}
            className="ml-1 mb-1"
          >
            <span className="d-none d-lg-inline">Download Report</span>
            <Icon path={mdiDownload} size={0.7} />
          </Button>

          <Button
            size="sm"
            outline
            color="dark"
            onClick={onViewAssessment}
            className="ml-1 mb-1"
          >
            <Icon path={mdiFileCheckOutline} size={0.7} />
          </Button>

          <Button
            size="sm"
            outline
            color="danger"
            onClick={onDeleteAssessment}
            className="ml-1 mb-1"
          >
            <Icon path={mdiClose} size={0.7} />
          </Button>
        </td>
      </tr>

      <Modal isOpen={isGeneratingReport}>
        <ModalHeader>Regenerating Assessment Report</ModalHeader>
        <ModalBody>
          <BusyMessage>
            Your download should start momentarily and the report will also be
            emailed.
          </BusyMessage>
        </ModalBody>
      </Modal>

      <Modal size="lg" isOpen={isViewing}>
        <ModalHeader toggle={onToggleViewingAssessment}>
          {getStudentName(assessment)}
        </ModalHeader>

        <ModalBody style={{ maxHeight: "70vh", overflow: "auto", padding: 0 }}>
          <AssessmentReview
            answers={assessment.answers}
            questions={assessment.questions}
          />
        </ModalBody>

        <ModalFooter>
          <div className="d-flex align-items-center justify-content-end">
            <Button onClick={onGenerateReport} color="primary" className="mr-2">
              Download Report&nbsp;
              <Icon path={mdiDownload} size={0.5} />
            </Button>
            <Button onClick={onDismissAssessment} color="dark">
              Dismiss
            </Button>
          </div>
        </ModalFooter>
      </Modal>

      <Modal size="lg" isOpen={isDeletingAssessment}>
        <ModalHeader toggle={onToggleDeletingAssessment}>
          Are you sure?
        </ModalHeader>
        <ModalBody>
          <DeleteConfirmation
            onCancel={onCancelDeletion}
            onDelete={onConfirmDelete}
          />
        </ModalBody>
      </Modal>
    </Fragment>
  );
}

export default function Dashboard() {
  const [query, setQuery] = useState("");
  const [assessments, isLoading] = useAllAssessmentResults();
  const [courses] = useAllCourses();

  const onResetQuery = () => setQuery("");

  const assessmentsToRender = filterAssessmentResults(
    assessments,
    courses,
    query
  );

  const { notify } = useNotification();
  const submitAssessmentResult = useSubmitAssessmentResult();
  const generateAssessmentReport = useGenerateAssessmentReport();
  const download = useDownload();

  const [isAddingAssessment, setAddingAssessment] = useState(false);
  const onAddAssessment = () => setAddingAssessment(true);
  const onToggleAddingAssessment = () => setAddingAssessment(toggle);

  const [isGeneratingReport, setGeneratingReport] = useState(false);

  const onSubmitManualAssessment = async (
    studentInformation: StudentInformationType,
    scores: ScoresMap
  ) => {
    setAddingAssessment(false);

    const studentName = studentInformation.name;
    const date = moment().format("MMMM DD, YYYY");

    submitAssessmentResult({
      student: studentInformation,
      durationInMillis: null,
      date,
      scores,
    });

    const reportParams = prepareAssessmentReportParams(
      studentInformation,
      date,
      scores
    );

    try {
      setGeneratingReport(true);

      const report = await generateAssessmentReport(reportParams);

      if (report) {
        const filename = `${studentInformation.name} Self-Assessment.pdf`;
        download(filename, report);
      } else {
        const message = `Failed to generate the assessment report for ${studentName}`;
        notify(message, "danger");
      }
    } catch (e) {
      const message = `Failed to generate the assessment report for ${studentName}`;
      notify(message, "danger");
    } finally {
      setGeneratingReport(false);
    }
  };

  return (
    <Fragment>
      <Layout>
        <Container>
          <Row className="mt-5">
            <Col>
              <div className="d-flex flex-column flex-md-row align-items-center justify-content-between">
                <h1 className="m-0 h3">Assessment Results</h1>
                <SupportNavigation active="dashboard" />
              </div>
            </Col>
          </Row>

          <Row className="mt-3">
            <Col className="rounded p-3 bg-white shadow">
              <div className="d-flex align-items-center justify-content-between">
                <AssessmentResultFilter query={query} onChange={setQuery} />
                <Button color="success" onClick={onAddAssessment}>
                  <span className="d-none d-md-inline mr-1">
                    Record Assessment Result
                  </span>
                  <Icon path={mdiPlus} size={0.5} />
                </Button>
              </div>

              <div className="mb-3" />

              <Table striped responsive size="sm">
                <thead>
                  <tr>
                    <th>Student</th>
                    <th>Date</th>
                    <th>Course</th>
                    <th />
                  </tr>
                </thead>

                <tbody>
                  {map(assessmentsToRender, (assessment, index) => (
                    <AssessmentRow
                      key={index}
                      assessment={assessment}
                      courses={courses}
                    />
                  ))}
                </tbody>
              </Table>

              <If test={isEmpty(assessmentsToRender) && !isLoading}>
                <div className="m-5 d-flex align-items-center justify-content-center">
                  <div className="px-3">
                    No assessment records match your search query.
                  </div>
                  <Button
                    color="primary"
                    outline
                    size="sm"
                    onClick={onResetQuery}
                  >
                    Reset Search
                  </Button>
                </div>
              </If>

              <If test={isLoading}>
                <div className="m-5 d-flex align-items-center justify-content-center">
                  <div className="px-3">Loading student assessment records</div>
                  <Spinner color="primary" />
                </div>
              </If>
            </Col>
          </Row>
        </Container>
      </Layout>

      <Modal
        size="lg"
        isOpen={isAddingAssessment}
        toggle={onToggleAddingAssessment}
      >
        <ModalHeader toggle={onToggleAddingAssessment}>
          Record Assessment Result
        </ModalHeader>
        <ModalBody>
          <ManualAssessmentEntryForm onSubmit={onSubmitManualAssessment} />
        </ModalBody>
      </Modal>

      <Modal isOpen={isGeneratingReport}>
        <ModalHeader>Regenerating Assessment Report</ModalHeader>
        <ModalBody>
          <BusyMessage>
            Your download should start momentarily and the report will also be
            emailed.
          </BusyMessage>
        </ModalBody>
      </Modal>
    </Fragment>
  );
}
