import React, { useState, useRef } from "react";
import { Button, Alert, Progress } from "reactstrap";
import { chunk } from "lodash";

import usePersistentState from "../hooks/usePersistentState";
import Questions from "./Questions";
import { QuestionType, AnswersMap } from "../types";
import If from "./helpers/If";
import areQuestionsComplete from "../utils/areQuestionsComplete";

interface Props {
  answers: AnswersMap;
  questions: QuestionType[];
  questionsPerPage: number;
  onSubmit: (scores: AnswersMap) => void;
}

export default function Assessment(props: Props) {
  const pages: QuestionType[][] = chunk(
    props.questions,
    props.questionsPerPage
  );

  const topRef = useRef<HTMLDivElement>(null);

  const [currentPage, setCurrentPage] = useState(0);
  const isLastPage = currentPage === pages.length - 1;
  const onNextPage = () => {
    setCurrentPage(currentPage + 1);

    setTimeout(() => {
      if (topRef.current !== null) {
        topRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }, 0);
  };

  const [values, setValues] = usePersistentState(
    props.answers,
    "assessment.answers"
  );
  const onChangeScore = (index: number, score: number) => {
    setValues({ ...values, [index]: { index, score } });
  };
  const onSubmit = () => {
    props.onSubmit(values);
  };

  const currentPageQuestions = pages[currentPage];

  const canProceed = areQuestionsComplete(currentPageQuestions, values);

  return (
    <div>
      <div ref={topRef} />
      <div className="mb-3 text-center text-muted">
        <small>
          Page {currentPage + 1} of {pages.length}
        </small>
        <div className="w-50 mx-auto">
          <Progress
            color="secondary"
            value={(100 * (currentPage + 1)) / pages.length}
          />
        </div>
      </div>
      <div className="my-3 text-muted text-center">
        <small>
          Select the number that most represents your experience.
          <br />
          <span>
            1 = Always, 2 = Almost Always, 3 = Sometimes, 4 = Almost Never, 5 =
            Never.
          </span>
        </small>
      </div>
      <div className="mb-3">
        <Questions
          questions={currentPageQuestions}
          values={values}
          onChange={onChangeScore}
        />
      </div>

      <If test={canProceed}>
        <div className="mb-3">
          <Alert
            color="primary"
            className="d-flex align-items-center justify-content-between"
          >
            <div>All questions are completed.</div>
            <If test={!isLastPage}>
              <Button
                disabled={!canProceed}
                onClick={onNextPage}
                color="primary"
                className="shadow"
              >
                Next
              </Button>
            </If>
            <If test={isLastPage}>
              <Button
                disabled={!canProceed}
                onClick={onSubmit}
                color="primary"
                className="shadow"
              >
                Submit
              </Button>
            </If>
          </Alert>
        </div>
      </If>

      <If test={!canProceed}>
        <div className="mb-3">
          <Alert
            color="dark"
            className="d-flex align-items-center justify-content-between"
          >
            All questions must be completed before proceeding to the next page.
            <Button color="dark" outline disabled>
              Next
            </Button>
          </Alert>
        </div>
      </If>
    </div>
  );
}
