import React, { Fragment } from "react";
import { Button, ModalBody, ModalFooter } from "reactstrap";

interface Props {
  courseName: string;
  onDelete: () => void;
  onCancel: () => void;
}

const CourseDeleteModal = ({ courseName, onDelete, onCancel }: Props) => {
  return (
    <Fragment>
      <ModalBody>
        Are you sure you want to permanently delete{" "}
        <strong>{courseName}</strong>?
      </ModalBody>
      <ModalFooter>
        <Button color="danger" onClick={onDelete}>
          Permanently Delete
        </Button>{" "}
        <Button color="secondary" onClick={onCancel}>
          Cancel
        </Button>
      </ModalFooter>
    </Fragment>
  );
};

export default CourseDeleteModal;
