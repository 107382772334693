import React, { Fragment } from "react";
import { Button } from "reactstrap";
import routes from "../routes";

export default function Footer() {
  return (
    <Fragment>
      <div className="mt-5 text-center">
        <div>
          <Button outline color="secondary" href={routes.support.email}>
            Contact Support
          </Button>
        </div>
      </div>
      <footer className="p-5 text-center">
        <div>
          <small>Copyright The StudyPro. All rights reserved.</small>
        </div>
      </footer>
    </Fragment>
  );
}
