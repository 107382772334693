import * as firebase from "firebase/app";
import useErrorProvider from "./useErrorProvider";

import { CourseType } from "../types";

export default function useAddCourse() {
  const { reportError } = useErrorProvider();
  const db = firebase.firestore();

  return (course: CourseType) =>
    db
      .collection("course")
      .add({
        ...course,
        created: firebase.firestore.Timestamp.now(),
      })
      .catch((e) => reportError(e, "useAddCourse"));
}
